import { Avatar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import EllipsisText from 'react-ellipsis-text';

import AutoAwesomeMosaicOutlinedIcon from '@mui/icons-material/AutoAwesomeMosaicOutlined';
import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';

import styles from './Card.module.css';

const getTempUrl = (url) => {
  if (url) return url.substr(0, 21) + ':8080' + url.substr(21);

  return url;
};

const isNotAvailable = (slots, range, field) => {
  let flag = false;
  const slot1 = slots[range][field];
  const slot2 = slots[range + 1][field];
  const slot3 = slots[range + 2][field];
  const slot4 = slots[range + 3][field];
  const slot5 = slots[range + 4][field];

  if (
    slot1?.length === 0 &&
    slot2?.length === 0 &&
    slot3?.length === 0 &&
    slot4?.length === 0 &&
    slot5?.length === 0
  )
    flag = true;

  return flag;
};

const getMaxLength = (slots, range, field) => {
  const slot1 = slots[range][field]?.length;
  const slot2 = slots[range + 1][field]?.length;
  const slot3 = slots[range + 2][field]?.length;
  const slot4 = slots[range + 3][field]?.length;
  const slot5 = slots[range + 4][field]?.length;

  return Math.max(slot1, slot2, slot3, slot4, slot5);
};

const getVisitReasons = (reasonsArray) => {
  const reasons = [];

  reasonsArray.forEach((item) => {
    if (item && item.name) reasons.push(item.name);
  });

  return reasons.join(', ');
};

const getTimeString = (timeString) => {
  return timeString.substr(0, 5) + timeString.substr(8);
};

const getTags = (visitTypes, ageTypes) => {
  const tags = [...visitTypes, ...ageTypes];
  return tags;
};

const Card = ({ data, range, onOpenModule, setTimeSlot }) => {
  const [active, setActive] = useState(false);
  const [activeTags, setActiveTags] = useState(false);
  const [activeAbout, setActiveAbout] = useState(false);
  const [activeInfo, setAtiveInfo] = useState(false);

  const handleOpenModule = (timeslot, provider) => () => {
    setTimeSlot(timeslot, provider);
    onOpenModule();
  };

  const handleActive = () => {
    setActive(true);
  };

  const handleActiveTags = () => {
    setActiveTags(true);
  };

  const handleActiveAbout = () => {
    setActiveAbout(true);
  };

  const handleActiveInfo = () => {
    setAtiveInfo(true);
  };

  useEffect(() => {
    setActive(false);
  }, [range]);

  return (
    <div className={styles.card}>
      <div className={styles.card__provider}>
        <Avatar
          src={data?.photo || null}
          alt="avatar"
          sx={{
            width: '120px',
            height: '120px',
            '@media(max-width: 840px)': {
              width: '80px',
              height: '80px',
            },
          }}
        />
        <div className={styles.card__info}>
          <div className={styles.card__providerHeader}>
            <h2>
              {data?.first_name} {data?.last_name}
            </h2>
            <p>{data?.specialty}</p>
          </div>

          <div className={styles.card__providerInfo}>
            <PortraitOutlinedIcon sx={{ color: '#7DCADA' }} />
            {!activeAbout && (
              <div>
                <EllipsisText text={data?.about} length="200" />
                {data?.about.length > 200 && (
                  <span
                    className={styles.more__span}
                    onClick={handleActiveAbout}
                  >
                    More
                  </span>
                )}
              </div>
            )}
            {activeAbout && <p>{data?.about}</p>}
          </div>

          <div className={styles.card__providerInfo}>
            <AutoAwesomeMosaicOutlinedIcon sx={{ color: '#7DCADA' }} />
            {!activeInfo && (
              <div>
                <EllipsisText
                  text={getVisitReasons(data?.visit_reasons_seen)}
                  length="200"
                />
                {getVisitReasons(data?.visit_reasons_seen)?.length > 200 && (
                  <span
                    className={styles.more__span}
                    onClick={handleActiveInfo}
                  >
                    More
                  </span>
                )}
              </div>
            )}
            {activeInfo && <p>{getVisitReasons(data?.visit_reasons_seen)}</p>}
          </div>

          <div
            className={styles.card__providerInfo}
            style={{ alignItems: 'center' }}
          >
            <BusinessOutlinedIcon sx={{ color: '#7DCADA' }} />
            <p style={{ marginTop: '2px', color: '#31405C' }}>
              {data?.physical_address}
            </p>
          </div>

          <div className={styles.card__tags}>
            {getTags(data?.visit_type, data?.ages_of_patients_seen).map(
              (item, index) => {
                if (index === 7 && !activeTags) {
                  return (
                    <div className={styles.moreTags} onClick={handleActiveTags}>
                      More +
                    </div>
                  );
                }

                if (index > 7 && !activeTags) {
                  return <></>;
                }

                return (
                  <div className={styles.tag}>
                    {item?.name || item?.age_range}
                  </div>
                );
              },
            )}
          </div>
        </div>
      </div>

      <div className={styles.card__timeslots}>
        {isNotAvailable(data?.time_slots, range.prev, 'CalendarTimes') && (
          <div className={styles.isNotAvailable}>No time slots available</div>
        )}

        {!isNotAvailable(data?.time_slots, range.prev, 'CalendarTimes') && (
          <div className={styles.card__timeslotWrapper}>
            <div className={styles.card__timeslot}>
              {data?.time_slots[range.prev]?.CalendarTimes.map(
                (item, index) => {
                  if (index === 7 && !active) {
                    return (
                      <div
                        className={styles.timeslot__button}
                        onClick={handleActive}
                      >
                        More
                      </div>
                    );
                  }

                  if (index > 7 && !active) {
                    return <></>;
                  }

                  return (
                    <div
                      className={styles.timeslot__button}
                      onClick={handleOpenModule(
                        {
                          data: data?.time_slots[range.prev],
                          item: item,
                        },
                        data,
                      )}
                    >
                      {getTimeString(item)}
                    </div>
                  );
                },
              )}
              {data?.time_slots[range.prev]?.CalendarTimes &&
                Array(
                  getMaxLength(data?.time_slots, range.prev, 'CalendarTimes') -
                    data?.time_slots[range.prev]?.CalendarTimes.length,
                )
                  .fill()
                  .map((_, index) => {
                    if (
                      index <
                        7 -
                          data?.time_slots[range.prev]?.CalendarTimes.length &&
                      !active
                    ) {
                      return (
                        <div className={styles.timeslot__button_line}> - </div>
                      );
                    }

                    if (index > 7 && !active) {
                      return <></>;
                    }

                    return (
                      active && (
                        <div className={styles.timeslot__button_line}> - </div>
                      )
                    );
                  })}
            </div>

            <div className={styles.card__timeslot}>
              {data?.time_slots[range.prev + 1]?.CalendarTimes.map(
                (item, index) => {
                  if (index === 7 && !active) {
                    return (
                      <div
                        className={styles.timeslot__button}
                        onClick={handleActive}
                      >
                        More
                      </div>
                    );
                  }

                  if (index > 7 && !active) {
                    return <></>;
                  }

                  return (
                    <div
                      className={styles.timeslot__button}
                      onClick={handleOpenModule(
                        {
                          data: data?.time_slots[range.prev + 1],
                          item: item,
                        },
                        data,
                      )}
                    >
                      {getTimeString(item)}
                    </div>
                  );
                },
              )}
              {data?.time_slots[range.prev + 1]?.CalendarTimes &&
                Array(
                  getMaxLength(data?.time_slots, range.prev, 'CalendarTimes') -
                    data?.time_slots[range.prev + 1]?.CalendarTimes.length,
                )
                  .fill()
                  .map((_, index) => {
                    if (
                      index <
                        7 -
                          data?.time_slots[range.prev + 1]?.CalendarTimes
                            .length &&
                      !active
                    ) {
                      return (
                        <div className={styles.timeslot__button_line}> - </div>
                      );
                    }

                    if (index > 7 && !active) {
                      return <></>;
                    }

                    return (
                      active && (
                        <div className={styles.timeslot__button_line}> - </div>
                      )
                    );
                  })}
            </div>

            <div className={styles.card__timeslot}>
              {data?.time_slots[range.prev + 2]?.CalendarTimes.map(
                (item, index) => {
                  if (index === 7 && !active) {
                    return (
                      <div
                        className={styles.timeslot__button}
                        onClick={handleActive}
                      >
                        More
                      </div>
                    );
                  }

                  if (index > 7 && !active) {
                    return <></>;
                  }

                  return (
                    <div
                      className={styles.timeslot__button}
                      onClick={handleOpenModule(
                        {
                          data: data?.time_slots[range.prev + 2],
                          item: item,
                        },
                        data,
                      )}
                    >
                      {getTimeString(item)}
                    </div>
                  );
                },
              )}
              {data?.time_slots[range.prev + 2]?.CalendarTimes &&
                Array(
                  getMaxLength(data?.time_slots, range.prev, 'CalendarTimes') -
                    data?.time_slots[range.prev + 2]?.CalendarTimes.length,
                )
                  .fill()
                  .map((_, index) => {
                    if (
                      index <
                        7 -
                          data?.time_slots[range.prev + 2]?.CalendarTimes
                            .length &&
                      !active
                    ) {
                      return (
                        <div className={styles.timeslot__button_line}> - </div>
                      );
                    }

                    if (index > 7 && !active) {
                      return <></>;
                    }

                    return (
                      active && (
                        <div className={styles.timeslot__button_line}> - </div>
                      )
                    );
                  })}
            </div>

            <div className={styles.card__timeslot}>
              {data?.time_slots[range.prev + 3]?.CalendarTimes.map(
                (item, index) => {
                  console.log('item', item, getTimeString(item));
                  if (index === 7 && !active) {
                    return (
                      <div
                        className={styles.timeslot__button}
                        onClick={handleActive}
                      >
                        More
                      </div>
                    );
                  }

                  if (index > 7 && !active) {
                    return <></>;
                  }

                  return (
                    <div
                      className={styles.timeslot__button}
                      onClick={handleOpenModule(
                        {
                          data: data?.time_slots[range.prev + 3],
                          item: item,
                        },
                        data,
                      )}
                    >
                      {getTimeString(item)}
                    </div>
                  );
                },
              )}

              {data?.time_slots[range.prev + 3]?.CalendarTimes &&
                Array(
                  getMaxLength(data?.time_slots, range.prev, 'CalendarTimes') -
                    data?.time_slots[range.prev + 3]?.CalendarTimes.length,
                )
                  .fill()
                  .map((_, index) => {
                    if (
                      index <
                        7 -
                          data?.time_slots[range.prev + 3]?.CalendarTimes
                            .length &&
                      !active
                    ) {
                      return (
                        <div className={styles.timeslot__button_line}> - </div>
                      );
                    }

                    if (index > 7 && !active) {
                      return <></>;
                    }

                    return (
                      active && (
                        <div className={styles.timeslot__button_line}> - </div>
                      )
                    );
                  })}
            </div>

            <div className={styles.card__timeslot}>
              {data?.time_slots[range.prev + 4]?.CalendarTimes.map(
                (item, index) => {
                  if (index === 7 && !active) {
                    return (
                      <div
                        className={styles.timeslot__button}
                        onClick={handleActive}
                      >
                        More
                      </div>
                    );
                  }

                  if (index > 7 && !active) {
                    return <></>;
                  }

                  return (
                    <div
                      className={styles.timeslot__button}
                      onClick={handleOpenModule(
                        {
                          data: data?.time_slots[range.prev + 4],
                          item: item,
                        },
                        data,
                      )}
                    >
                      {getTimeString(item)}
                    </div>
                  );
                },
              )}
              {data?.time_slots[range.prev + 4]?.CalendarTimes &&
                Array(
                  getMaxLength(data?.time_slots, range.prev, 'CalendarTimes') -
                    data?.time_slots[range.prev + 4]?.CalendarTimes.length,
                )
                  .fill()
                  .map((_, index) => {
                    if (
                      index <
                        7 -
                          data?.time_slots[range.prev + 4]?.CalendarTimes
                            .length &&
                      !active
                    ) {
                      return (
                        <div className={styles.timeslot__button_line}> - </div>
                      );
                    }

                    if (index > 7 && !active) {
                      return <></>;
                    }

                    return (
                      active && (
                        <div className={styles.timeslot__button_line}> - </div>
                      )
                    );
                  })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;
