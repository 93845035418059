import { styled } from '@mui/material';

export const InsuranceMenuWrapper = styled('div')(() => ({
  color: 'rgba(49, 64, 92, 1)!important',
  fontSize: 14,
  fontWeight: 600,
  backgroundColor: '#fff',
  border: '1px solid rgba(49, 64, 92, 0.3)',
  borderRadius: 100,
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  width: '24%',
  '@media(max-width: 768px)': {
    maxWidth: 'unset',
    width: '100%',
  },
}));

export const InsuranceBox = styled('div')(() => ({
  width: '100%',
  cursor: 'pointer',
  paddingTop: '7px',
  padding: '5px 15px',
  overflow: 'hidden',

  span: {
    whiteSpace: 'nowrap',
  },

  '@media(max-width: 768px)': {
    padding: '14px 15px',
  },
}));

export const InsurancePaper = styled('div')(() => ({
  cursor: 'default',
  border: '1px solid rgba(49, 64, 92, 0.3)',
  backgroundColor: '#fff',
  padding: '15px',
  width: '150%',
  height: 500,
  position: 'absolute',
  zIndex: 1000,
  top: '100%',
  right: 0,
  borderRadius: 24,
  marginTop: 5,
  overflow: 'hidden',
  overflowY: 'auto',

  '@media(max-width: 768px)': {
    width: '100%',
  },
}));

export const InsuranceMenuHeader = styled('h4')(() => ({
  margin: 0,
  marginBottom: 10,
  color: 'rgba(49, 64, 92, 1)',
  fontSize: 18,
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
}));

export const InsurancePlanSub = styled('h6')(() => ({
  margin: 0,
  fontSize: 16,
  textDecoration: 'underline',
  cursor: 'pointer',
  fontWeight: 600,
  marginLeft: 'auto',
}));

export const InsuranceMenuList = styled('div')(({ active }) => ({
  position: 'absolute',
  padding: '0 15px',
  margin: '15px 0',
  width: '100%',
  top: 64,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  transition: 'all .3s',
  overflowY: 'auto',
  left: active ? '-100%' : 0,
}));

export const InsuranceSubMenuList = styled('div')(({ active }) => ({
  position: 'absolute',
  padding: '0 15px',
  width: '100%',
  top: 64,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  transition: 'all .3s',
  right: active ? 0 : '-100%',
}));

export const InsuranceMenuItem = styled('div')(() => ({
  cursor: 'pointer',
  color: 'rgba(49, 64, 92, 1)',
  padding: '5px',
  border: '1px solid rgba(49, 64, 92, 0)',
  borderRadius: 2,
  fontWeight: 400,
  display: 'flex',
  alignItems: 'center',
  gap: 10,
  transition: 'all .2s',

  '&:hover': {
    backgroundColor: 'rgba(49, 64, 92, 0.1)',
    border: '1px solid rgba(49, 64, 92, 0.3)',
  },

  img: {
    width: 40,
    height: 40,
  },
}));

export const InsuranceSearchInput = styled('input')(() => ({
  fontSize: 14,
  color: 'rgba(49, 64, 92, 1)',
  fontWeight: 600,
  border: 'none',
  outline: 'none',
  width: '100%',
  padding: '5px 0',
  '&::placeholder': {
    fontWeight: 600,
    opacity: '0.5',
  },

  '@media(max-width: 768px)': {
    padding: '0',
  },
}));
