import { Autocomplete, IconButton, TextField, styled } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from 'react';

const TextFieldStyled = styled(TextField)(() => ({
  '& .MuiInputBase-root.Mui-error': {
    border: '1px solid #f81d22 !important',
  },
  '& .MuiFormLabel-root.Mui-error': {
    color: '#f81d22 !important',
  },
  '& .MuiFormLabel-root': {
    fontFamily: 'Open Sans',
    left: 8,
    color: 'rgba(49, 64, 92, 1) !important',
    fontWeight: 600,
    fontSize: '14px',
    top: '0px',
  },
  '& .MuiInputLabel-shrink': {
    transform: 'translate(12px, 8px) scale(0.75)',
  },
  '& .MuiInputBase-root': {
    paddingTop: '19px',
    fontFamily: 'Open Sans',
    color: 'rgba(49, 64, 92, 1)',
    backgroundColor: 'transparent',
    border: '1px solid rgba(49, 64, 92, 0.3)',
    borderRadius: '100px',
    paddingLeft: '15px !important',
    paddingRight: '10px !important',
    fontSize: '14px',
    '&::after': {
      display: 'none !important',
    },
    '&::before': {
      display: 'none !important',
    },
    svg: {
      color: 'rgba(49, 64, 92, 1)',
    },
  },
}));

const TextFieldFilterStyled = styled(TextField)(({ active }) => ({
  '& .MuiFormLabel-root': {
    fontFamily: 'Open Sans',
    left: 8,
    color: active ? 'rgba(255,255,255,0.7)' : 'rgba(49, 64, 92, 1) !important',
    fontWeight: 600,
    fontSize: '13px',
    top: '-5px',
    transition: 'all .3s',
  },
  '& .MuiInputLabel-shrink': {
    transition: 'all .3s',
    transform: 'translate(12px, 6px) scale(0.75)',
  },
  '& .MuiInputBase-root': {
    paddingTop: '8px',
    fontFamily: 'Open Sans',
    color: active ? '#fff' : 'rgba(49, 64, 92, 1)',
    backgroundColor: active ? 'rgba(49, 64, 92, 1)' : '#fff',
    border: '1px solid rgba(49, 64, 92, 0.3)',
    borderRadius: '100px',
    paddingLeft: '15px !important',
    paddingRight: '60px !important',
    transition: 'all .3s',
    fontSize: '13px',
    '&::after': {
      display: 'none !important',
    },
    '&::before': {
      display: 'none !important',
    },
    svg: {
      transition: 'all .3s',
      color: active ? '#fff' : 'rgba(49, 64, 92, 1)',
    },
  },
}));

const StyledAutocomplete = styled(Autocomplete)(() => ({}));

const StyledIconButton = styled(IconButton)(() => ({
  cursor: 'unset',
  width: 28,
  height: 28,
  '&:hover': {
    backgroundColor: 'unset',
  },
}));

const types = {
  filter: 'filter',
  global: 'global',
};

const BaseSelect = ({
  options,
  label,
  placeholder,
  value,
  onChange,
  type = types.global,
  overrideWrapperSX,
  customField,
  error,
  disableClearable,
  helperText,
}) => {
  const [open, setOpen] = useState(false);
  const handleChange = (_event, newValue) => {
    onChange(newValue);
  };

  const handleOnOpen = () => {
    setOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const bar = document.querySelector('.modal-scroll');
    if (bar && open) bar.style.overflowY = 'hidden';
    if (bar && !open) bar.style.overflowY = 'unset';
    console.log('open', bar?.style);
  }, [open]);

  return type === types.global ? (
    <StyledAutocomplete
      onClose={handleOnOpen}
      onOpen={handleOnOpen}
      size="small"
      value={value || null}
      onChange={handleChange}
      fullWidth
      id="tags-outlined"
      sx={{ ...overrideWrapperSX }}
      options={options}
      disableClearable={disableClearable}
      getOptionLabel={(option) =>
        customField ? option[customField] : option?.name
      }
      componentsProps={{
        popupIndicator: {
          component: (props) => (
            <StyledIconButton disableRipple {...props}>
              <ExpandMoreIcon />
            </StyledIconButton>
          ),
        },
      }}
      renderInput={(params) => (
        <TextFieldStyled
          variant="filled"
          {...params}
          label={label}
          placeholder={placeholder || ''}
          InputProps={{
            ...params.InputProps,
          }}
          error={error}
          helperText={helperText}
        />
      )}
    />
  ) : (
    <StyledAutocomplete
      size="small"
      value={value || null}
      onChange={handleChange}
      fullWidth
      id="tags-outlined"
      options={options}
      getOptionLabel={(option) =>
        customField ? option[customField] : option?.name
      }
      sx={{ ...overrideWrapperSX }}
      componentsProps={{
        popupIndicator: {
          component: (props) => (
            <StyledIconButton disableRipple {...props}>
              <ExpandMoreIcon />
            </StyledIconButton>
          ),
        },
      }}
      renderInput={(params) => (
        <TextFieldFilterStyled
          active={value && value !== null && value !== ''}
          variant="filled"
          {...params}
          label={label}
          placeholder={placeholder || ''}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
};

export default BaseSelect;
