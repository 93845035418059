import { Autocomplete, IconButton, TextField, styled } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';

const TextFieldStyled = styled(TextField)(() => ({
  width: '100%',
  input: {
    fontWeight: 600,
    paddingRight: '60px !important',
  },
  '&:hover': {
    '& .MuiInputBase-root': {
      backgroundColor: '#fff !important',
    },
  },
  '& .MuiInputBase-root.Mui-error': {
    border: '1px solid #f81d22 !important',
  },
  '& .MuiFormLabel-root.Mui-error': {
    color: '#f81d22 !important',
  },
  // '& .MuiInputLabel-filled': {
  //   display: 'none',
  // },
  '& .MuiFormLabel-root': {
    fontFamily: 'Open Sans',
    left: 8,
    color: 'rgba(49, 64, 92, 1) !important',
    fontWeight: 600,
    fontSize: '14px',
    top: '2px',
  },
  '& .MuiInputLabel-shrink': {
    display: 'none',
  },
  '& .MuiInputBase-root': {
    paddingTop: '12px',
    paddingBottom: '10px !important',
    fontFamily: 'Open Sans',
    color: 'rgba(49, 64, 92, 1)',
    backgroundColor: '#fff !important',
    border: '1px solid rgba(49, 64, 92, 0.3)',
    borderRadius: '100px',
    paddingLeft: '15px !important',
    paddingRight: '10px !important',
    fontSize: '14px',
    '&::after': {
      display: 'none !important',
    },
    '&::before': {
      display: 'none !important',
    },
    svg: {
      color: 'rgba(49, 64, 92, 1)',
    },
  },

  '@media(max-width: 1000px)': {
    width: '100%',
    '& .MuiInputLabel-shrink': {
      transform: 'translate(12px, 4px) scale(0.75)',
    },
    '& .MuiInputBase-root': {
      paddingTop: '12px',
      paddingLeft: '15px !important',
      paddingRight: '10px !important',
      fontSize: '13px',
    },
  },

  '@media(max-width: 600px)': {
    width: '100%',
  },
}));

const StyledIconButton = styled(IconButton)(() => ({
  cursor: 'unset',
  width: 28,
  height: 28,
  '&:hover': {
    backgroundColor: 'unset',
  },
}));

const StyledAutocomplete = styled(Autocomplete)(() => ({
  width: '25%',
  '@media(max-width: 1000px)': {
    width: '25%',
  },

  '@media(max-width: 768px)': {
    width: '100%',
  },
}));

const LightBaseSelect = ({
  options,
  label,
  placeholder,
  value,
  onChange,
  overrideWrapperSX,
  customField,
  error,
  helperText,
}) => {
  const handleChange = (_event, newValue) => {
    onChange(newValue);
  };

  return (
    <StyledAutocomplete
      size="small"
      value={value || null}
      onChange={handleChange}
      fullWidth
      id="tags-outlined"
      sx={{ ...overrideWrapperSX }}
      options={options}
      getOptionLabel={(option) =>
        customField ? option[customField] : option?.name
      }
      componentsProps={{
        popupIndicator: {
          component: (props) => (
            <StyledIconButton disableRipple {...props}>
              <ExpandMoreIcon />
            </StyledIconButton>
          ),
        },
      }}
      renderInput={(params) => (
        <TextFieldStyled
          variant="filled"
          {...params}
          label={label}
          placeholder={placeholder || ''}
          InputProps={{
            ...params.InputProps,
          }}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};

export default LightBaseSelect;
