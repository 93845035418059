/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import ClearIcon from '@mui/icons-material/Clear';

import {
  InsuranceBox,
  InsuranceMenuHeader,
  InsuranceMenuItem,
  InsuranceMenuList,
  InsuranceMenuWrapper,
  InsurancePaper,
  InsurancePlanSub,
  InsuranceSearchInput,
  InsuranceSubMenuList,
} from './InsuranceMenu.styled';

import useOutsideClick from '../../hooks/useOutsideClick';
import PriceIcon from '../../assets/PriceIcon';
import { IconButton } from '@mui/material';

const InsuranceMenu = ({
  placeholder,
  onOpen,
  onClose,
  active,
  options,
  onMenuClick,
  activeMenuItem,
  onSubMenuClick,
  activeSubMenuItem,
  onClearAll,
  isCustomItem,
  notPlan,
}) => {
  const ref = useOutsideClick(onClose);

  useEffect(() => {
    if (activeSubMenuItem || isCustomItem) onClose();
  }, [activeSubMenuItem, isCustomItem]);

  return (
    <InsuranceMenuWrapper ref={ref}>
      <InsuranceBox onClick={onOpen}>
        {!active && !isCustomItem && (
          <>
            {!activeSubMenuItem ? (
              <span>{placeholder}</span>
            ) : (
              <span>
                {activeMenuItem?.name} - {activeSubMenuItem?.name}
              </span>
            )}
          </>
        )}
        {isCustomItem && <span>Self-pay / Cash-pay</span>}
        {active && !isCustomItem && (
          <InsuranceSearchInput placeholder="Insurance carrier and plan" />
        )}
      </InsuranceBox>
      {(activeMenuItem || activeSubMenuItem || isCustomItem) && (
        <IconButton
          sx={{
            width: '25px',
            height: '25px',
            marginLeft: 'auto',
            marginRight: '15px',
          }}
          onClick={onClearAll}
        >
          <ClearIcon
            sx={{ width: '20px', height: '20px', color: 'rgba(49, 64, 92, 1)' }}
          />
        </IconButton>
      )}

      {active && (
        <InsurancePaper>
          {activeMenuItem && !isCustomItem ? (
            <InsuranceMenuHeader>
              <span>Plans</span>
              <InsurancePlanSub onClick={onMenuClick('not-plan')}>
                I can't find my plan
              </InsurancePlanSub>
            </InsuranceMenuHeader>
          ) : (
            <InsuranceMenuHeader>Options</InsuranceMenuHeader>
          )}

          <InsuranceMenuList active={activeMenuItem && !isCustomItem ? 1 : 0}>
            {!notPlan && (
              <InsuranceMenuItem onClick={onMenuClick('myself')}>
                <PriceIcon />
                Self-pay / Cash-pay
              </InsuranceMenuItem>
            )}
            {!notPlan &&
              options?.map((item) => (
                <InsuranceMenuItem onClick={onMenuClick(item)}>
                  <img src={item.logo} alt="logo" /> {item.name}
                </InsuranceMenuItem>
              ))}
            {notPlan && (
              <InsuranceMenuItem onClick={onMenuClick('myself')}>
                Click here to confirm: I must submit my insurance for
                verification prior to appointment or my appointment will be
                canceled
              </InsuranceMenuItem>
            )}
          </InsuranceMenuList>

          <InsuranceSubMenuList
            active={activeMenuItem && !isCustomItem ? 1 : 0}
          >
            {activeMenuItem?.plans?.map((item) => (
              <InsuranceMenuItem onClick={onSubMenuClick(item)}>
                {item.name}
              </InsuranceMenuItem>
            ))}
          </InsuranceSubMenuList>
        </InsurancePaper>
      )}
    </InsuranceMenuWrapper>
  );
};

export default InsuranceMenu;
